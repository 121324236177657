
import Vue, { PropType } from "vue";
import { formatDatetime } from "@/utils/dateFormatter";

import { DialogCloseType, DialogI } from "@/types/dialog";
import { validator } from "@/utils/validators";
import BaseDialog from "@/components/dialog/BaseDialog.vue";
import Btn from "@/components/v2/base/Btn.vue";
import AudioButton from "@/components/base/AudioButton.vue";
import TextareaField from "@/components/form/inputs/TextareaField.vue";
import { Exercise, Note } from "@prestonly/preston-common";

export default Vue.extend({
  name: "AddNote",
  components: {
    BaseDialog,
    Btn,
    TextareaField,
    AudioButton,
  },
  mixins: [validator],

  data() {
    return {
      userNote: "",
    };
  },

  computed: {
    existingNote(): Note {
      return this.dialog.config.payload?.existingNote?.note || "";
    },
    exercise(): Exercise {
      return this.dialog.config.payload?.exercise || {};
    },
  },

  methods: {
    formatDatetime,
    submit() {
      this.dialog.close({ type: DialogCloseType.SUBMITTED, payload: { note: this.userNote } });
    },
    cancel() {
      this.dialog.close({ type: DialogCloseType.CLOSED });
    },
  },

  mounted() {
    if (this.existingNote) {
      this.userNote = this.existingNote?.rawNote || "";
    }
  },

  props: {
    dialog: {
      type: Object as PropType<DialogI>,
      required: true,
    },
  },
});
